<!--
 * @Autor: syq
 * @Date: 2021-07-12 19:23:20
-->
<template>
  <div id="tab">
    <van-tabs v-model="active" @change="change">
      <van-tab
        :title="item.title"
        :name="item.status"
        v-for="item in list"
        :key="item.status"
        class="van-tab-line"
      >
        <div class="order-item" v-if="item.status === active">
          <div>
            <div v-if="data && data.length > 0">
              <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="getMessageList(item.status)"
              >
                <div
                  class="order-head"
                  v-for="(item, index) in data"
                  :key="index"
                >
                  <!-- 头 -->
                  <div class="order-floor">
                    <span class="shop-img">
                      <img :src="findlogo(item)" alt="" />
                    </span>
                    <span class="order-name">{{ item.storeName }}</span>
                    <span class="order-status">{{
                      status.get(item.tradeStatus)
                    }}</span>
                  </div>
                  <!-- 商品信息 -->
                  <Order :card="item" />
                  <!-- 底-->
                  <div class="order-footer">
                    <p class="order-title">
                      共<span class="order-title-font">{{
                        item.totalQuantity
                      }}</span
                      >件商品 订单总额：<span class="order-title-font"
                        >￥{{ item.payableAmount }}</span
                      >
                    </p>
                    <!-- <button class="order-btn1" v-show="item.tradeStatus !== -1">
            立即支付
          </button> -->
                    <div class="buttonLayout">
                      <button
                        class="order-btn1"
                        v-if="item.tradeStatus === 45"
                        @click="() => triggerTradeOrder(item)"
                      >
                        确认收货
                      </button>
                      <button
                        class="order-btn1"
                        v-if="item.tradeStatus === 5"
                        @click="() => paybank(item)"
                      >
                        查看打款信息
                      </button>
                       <button
                        class="order-btn1"
                        v-if="item.tradeStatus === 5"
                        @click="() => topay(item)"
                      >
                        付款
                      </button>
                      <button class="order-btn2" @click="() => details(item)">
                        查看详情
                      </button>
                    </div>
                  </div>
                </div>
              </van-list>
            </div>
          </div>
          <van-dialog v-model="show" :showCancelButton="false">
            <div class="order-paymessage">
              <p>
                请向以下商家汇款
                <span>
                  ￥{{(paymessage.totalActualAmount || 0).toFixed(2)}}&nbsp;
                </span>
                用于支付货款
              </p>
              <div
                class="paymessage-name"
                v-for="(payitem, index) in paylist"
                :key="index"
              >
                <p>
                  户名：<span>{{ payitem.bank.name }}</span>
                </p>
                <p>
                  开户行：<span> {{ payitem.bank.bankName }}</span>
                </p>
                <p>
                  账户：<span> {{ payitem.bank.bankAccount }}</span>
                </p>
              </div>
            </div>
          </van-dialog>
        </div>
      </van-tab>
      <div class="order-empty" v-if="data && data.length === 0">
        <!-- <img class="icon" src="../../assets/icon/store-touxiang-icon@2x.png" /> -->
        <p class="text">未查询到订单</p>
      </div>
    </van-tabs>
  </div>
</template>

<script>
import Order from "./components/card.vue";
import { Toast } from "vant";
import storeTouxiang from "../../assets/icon/store-touxiang-icon@2x.png";
import { orderBusiness, triggerTrade, getPaymentinformation } from "./service";
import { trackEvent } from '../../utils/utils'

export default {
  components: { Order },
  data() {
    return {
      active: "",
      title: "",
      data: [],
      storeTouxiang,
      showCancelButton: false,
      paymessage: {},
      paylist:[],
      show: false,
      loading: false,
      finished: false,
      currentPage: 0,
      status: new Map([
        // [1, "初始化"],
        [5, "待付款"],
        [25, "待审核"],
        [40, "待发货"],
        [44, "部分发货"],
        [45, "待收货"],
        [0, "交易完成"],
        [-1, "交易取消"],
      ]),
      list: [
        {
          status: "",
          title: "全部",
        },
        {
          status: "5",
          title: "待付款",
        },
        {
          status: "25",
          title: "待审核",
        },
        {
          status: "40",
          title: "待发货",
        },
        {
          status: "44",
          title: "部分发货",
        },

        {
          status: "45",
          title: "待收货",
        },
        {
          status: "0",
          title: "交易完成",
        },
        {
          status: "-1",
          title: "交易取消",
        },
      ],
    };
  },
  created() {},
  mounted() {
    if (this.$route.query.status) {
      this.active = this.$route.query.status;
      trackEvent('我的页面', '点击我的订单')
    }
    this.getMessageList1(this.active, 1);
  },
  // watch: {
  //   active(oldActive, newActive) {
  //     this.$refs[newActive].getMessageList(newActive);
  //   },
  // },
  methods: {
    //去付款
    topay(id){
      this.$router.push({
        path: "/pay",
        query: { id: id.parentOrderNo,
            orderNo:id.orderNo,
            storeCode:id.storeCode
            },
      });
    },
    //店铺logo
    findlogo(item){
      return item?.ext ? JSON.parse(JSON.parse(item.ext).logo)[0]?.thumbUrl : 'http://www.liby.com.cn/images/logo.png'
    },
    async paybank(item) {
      await getPaymentinformation(item.parentOrderNo).then((res) => {
        if (res.status === "0") {
          this.paymessage = res.data;
          this.paylist = res?.data?.orderList.filter(
            (k) => k.storeCode === item.storeCode
          );
          this.show = true;
        } else {
          Toast(res.msg);
        }
      });
    },
    async getMessageList(active, currentPage) {
      this.loading = true;
      await orderBusiness(active, currentPage ?? ++this.currentPage).then(
        (respones) => {
          this.loading = false;
          if (respones.status === "0") {
            this.data = this.data.concat(respones.data.items);
            this.currentPage = respones.data.page;
            if (respones.data.items.length < 10) {
              this.finished = true;
            }
          } else {
            this.loading = false;
            this.finished = true;
          }
        }
      );
    },
    async getMessageList1(active, currentPage) {
      this.loading = true;
      await orderBusiness(active, currentPage).then((respones) => {
        this.loading = false;
        if (respones.status === "0") {
          this.data = respones.data.items;
          this.currentPage = respones.data.page;
        } else {
          this.loading = false;
        }
      });
    },
    details(item) {
      const { orderNo } = item;
      this.$router.push({
        path: "/orderdetails",
        query: { orderNo, isDetail: true },
      });
    },
    async triggerTradeOrder(item) {
      const res = await triggerTrade(item.orderNo);
      if (res.status === "0") {
        Toast("收货成功");
        this.getMessageList1(this.active, 1);
      } else {
        Toast(res.msg);
      }
    },
    change(val) {
      this.getMessageList1(val, 1);
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap");
</style>
<style lang="less" scoped>
#tab {
  width: 100%;
  min-height: 100%;
  background-color: @BackgroundColor;
  /deep/ .van-tabs__wrap {
    border-top: 1px solid #f2f2f2;
  }
  /deep/.order-item {
    padding: 0 12px;
    .order-head {
      margin-top: 8px;
      border-radius: 12px;
      .order-floor {
        display: flex;
        width: 100%;
        height: 48px;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        background-color: #fff;
        .shop-img {
          width: 48px;
          padding: 8px;
          img {
            width: 32px;
            height: 32px;
          }
        }
        .order-name {
          flex: 1;
          font-size: 14px;
          font-weight: 500;
          color: #000;
          line-height: 48px;
        }
        .order-status {
          margin-right: 8px;
          width: 50px;
          color: #ff9e48;
          font-size: 12px;
          line-height: 48px;
          text-align: right;
        }
      }
    }

    .order-footer {
      padding: 8px 0;
      background-color: rgba(255, 255, 255, 1);
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      .order-title {
        width: 100%;
        margin: 0;
        padding: 8px 23px 8px 0;
        text-align: right;
        font-weight: 400;
        font-size: 12px;
        color: #666;
      }
      .buttonLayout {
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
      button {
        border-radius: 8px;
        background-color: rgba(255, 255, 255, 0);
        border: 1px solid rgba(228, 228, 228, 1);
        height: 30px;
        font-weight: 400;
        font-size: 12px;
        padding: 0 8px;
        line-height: 20px;
      }
      .order-btn1 {
        margin-right: 8px;
        color: #96979b;
      }
      .order-btn2 {
        margin-right: 16px;
        padding: 0 12px;
        color: #0095da;
        border: 1px solid #0095da;
      }
    }
    .van-dialog {
      padding-left: 20px !important;
      border-radius: 8px !important;
      font-size: 15px !important;
      .order-paymessage {
        color: #aaaaaa;
        p span {
          color: #d9001b;
        }
      }
      .paymessage-name p span {
        color: #f5a804;
      }
    }
  }
  .order-empty {
    padding-top: 150px;
    text-align: center;
    .icon {
      width: 120px;
    }
    .text {
      font-size: 13px;
      color: #999;
    }
  }

  /deep/ .van-tabs__line {
    background-color: #0095da !important;
    height: 2px;
  }

  /deep/ .van-tab--active {
    color: #0095da;
    font-weight: 600;
  }

  /deep/ .van-card__price {
    font-family: "Montserrat", sans-serif;
  }

  /deep/ .van-card__price {
    font-family: "Montserrat", sans-serif;
  }
  /deep/ .van-card__price-integer {
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    font-size: 16px;
  }
  /deep/ .van-card__price-decimal {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 500;
  }
  /deep/ .van-card__num {
    font-family: "Montserrat", sans-serif;
  }
  /deep/ .van-image {
    img {
      border-radius: 4px;
    }
  }
  /deep/ .van-card__desc {
    color: #1c2529 !important;
    opacity: 0.5;
  }
  .order-title-font {
    font-family: "Montserrat", sans-serif;
  }
  /deep/ .van-card__title {
    font-weight: 600;
  }
}
</style>
